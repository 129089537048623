import React, { useEffect, useState, useCallback } from "react";
import Airtable from "airtable";
import "./VideoPlayer.css";

const App = () => {
  const [modules, setModules] = useState([]);
  const [uniqueModules, setUniqueModules] = useState([]);
  const [selectedModule, setSelectedModule] = useState(null);
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const airtableBase = new Airtable({ apiKey: "patEqxfhYkPKbBIzS.fc9cad6b2d6cf79156248c2153656d89c30ee5dfba65deaadb24d03b8b6b750c" }).base("app094f5b3LYIx37j");

  const fetchModules = useCallback(() => {
    airtableBase("VideoCourse")
      .select({ view: "Grid view" })
      .eachPage((records, fetchNextPage) => {
        const fetchedModules = records.map((record) => ({
          id: record.id,
          title: record.get("Titolo"),
          module: record.get("Modulo"),
          videoLink: record.get("Link"),
        }));
        setModules(fetchedModules);
        const unique = fetchedModules.filter(
          (m, index, self) => self.findIndex((t) => t.module === m.module) === index
        );
        setUniqueModules(unique);
        fetchNextPage();
      });
  }, [airtableBase]);

  useEffect(() => {
    if (user) {
      fetchModules();
    }
  }, [user, fetchModules]);

  const handleLogin = () => {
    airtableBase("ClientKeyAcademy")
      .select({ view: "Grid view" })
      .eachPage(
        (records, fetchNextPage) => {
          const foundUser = records.find(
            (record) =>
              record.get("Email") === email && record.get("Password") === password
          );

          if (foundUser) {
            console.log("Login riuscito");
            setUser({ email });
            setError("");
          } else {
            setError("Email o password non validi.");
          }
          fetchNextPage();
        },
        (err) => {
          console.error("Errore nel recupero dati:", err);
          setError("Errore nella connessione con Airtable.");
        }
      );
  };

  const handleModuleClick = (module) => {
    console.log("Modulo selezionato:", module);
    setSelectedModule(module);
  };

  const renderLogin = () => (
    <div className="login-container">
      <h1 className="login-title">Accedi ad Eccomerce Brand</h1>
      <div className="login-form">
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="login-input"
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="login-input"
        />
        <button onClick={handleLogin} className="login-button">
          Accedi
        </button>
        {error && <p className="login-error">{error}</p>}
      </div>
    </div>
  );

  const renderMenu = () => (
    <div className="module-selector">
      <h1 className="module-title">Seleziona un Modulo</h1>
      {uniqueModules.map((module) => (
        <button
          key={module.module}
          className="module-button"
          onClick={() => handleModuleClick(module.module)}
        >
          Modulo {module.module}: {module.title}
        </button>
      ))}
    </div>
  );

  const renderLessons = () => {
    const lessons = modules.filter((m) => m.module === selectedModule);
    return (
      <div>
        <h1 className="module-title">Lezioni del Modulo {selectedModule}</h1>
        <button className="module-button" onClick={() => setSelectedModule(null)}>
          Torna al Menù
        </button>
        <div className="lesson-list">
          {lessons.map((lesson) => (
            <div key={lesson.id} className="lesson-item">
              <h3>{lesson.title}</h3>
              <div className="video-container">
                <iframe
                  title={`Video: ${lesson.title}`}
                  src={`https://fast.wistia.net/embed/iframe/${lesson.videoLink}`}
                  allow="autoplay; fullscreen"
                  frameBorder="0"
                  style={{ width: "100%", height: "180px" }}
                ></iframe>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return <div>{user ? (selectedModule ? renderLessons() : renderMenu()) : renderLogin()}</div>;
};

export default App;
